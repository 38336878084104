@import './sass/mixins';
@import '../portal.home/index.scss';

// .bg-main{
//   background-image: -webkit-gradient(linear, left top, left bottom, color-stop(80%, transparent), to(#032832)),
//     -webkit-gradient(linear, left top, left bottom, from(#032832), to(transparent)),
//     url(../../public/static/images/Sea-01.jpeg);
//   background-image: linear-gradient(180deg, transparent 80%, #032832), linear-gradient(180deg, #032832, transparent),
//     url(../../public/static/images/Sea-01.jpeg);
//   background-position: 0px 0px, 0px 0px, 50% 50%;
//   background-size: auto, auto, cover;
//   background-repeat: repeat, repeat, no-repeat;
// }

.bg-section {
  //   display: -webkit-box;
  //   display: -webkit-flex;
  //   display: -ms-flexbox;
  //   display: flex;
  padding-top: 4rem;
  padding-bottom: 8rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(80%, transparent), to(#032832)),
    -webkit-gradient(linear, left top, left bottom, from(#032832), to(transparent)),
    url(../../public/static/images/Sea-01.jpeg);
  background-image: linear-gradient(180deg, transparent 80%, #032832), linear-gradient(180deg, #032832, transparent),
    url(../../public/static/images/Sea-01.jpeg);
  background-position: 0px 0px, 0px 0px, 50% 50%;
  background-size: auto, auto, cover;
  background-repeat: repeat, repeat, no-repeat;

  h1,
  h2,
  h3,
  h4,
  .MuiTypography-p {
    color: #fff;
  }

  .MuiTypography-p.text-center {
    display: block;
    text-align: center;
  }

  img,
  h2,
  h3,
  .MuiTypography-p {
    padding-bottom: 2rem;
  }
}

.text-color-white {
  color: #fff;
}

.banner-section {
  background-color: transparent;
  background-image: url(../../public/static/images/20210118174721bb29ee.jpg);
  background-size: auto;
  background-repeat: no-repeat;
  background-position: 100% 0%;
  background-attachment: fixed;
  height: auto;
  padding: 3rem 1rem;

  @include breakpoint(medium) {
    height: 14rem;
    padding: inherit;
  }

  h2,
  .MuiTypography-p {
    color: #fff;
    text-align: center;
    display: block;
  }
  h2 {
    padding-top: 2rem;
  }
}

.state {
  height: 24px;
  min-width: 24px;
  line-height: 0;
  border-radius: 6px;
  cursor: default;
  -webkit-box-align: center;
  align-items: center;
  white-space: nowrap;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  text-transform: capitalize;
  padding: 0px 6px;
  font-size: 0.75rem;
  font-weight: 700;
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &.success {
    color: rgb(17, 141, 87);
    background-color: rgba(34, 197, 94, 0.16);
  }
  &.pending{
    color: rgb(183, 110, 0);
    background-color: rgba(255, 171, 0, 0.16);
  }

  &.warning {
    color: rgb(0, 108, 156);
    background-color: rgba(0, 184, 217, 0.16);
  }
  &.error {
    color: rgb(183, 29, 24);
    background-color: rgba(255, 86, 48, 0.16);
  }

  &.published {
    color: rgb(0, 108, 156);
    background-color: rgba(0, 184, 217, 0.16);
  }
  &.draft {
    color: rgb(99, 115, 129);
    background-color: rgba(145, 158, 171, 0.16);
  }
}




.detail-editors{
  &>div{
    padding-bottom: 1.5rem;
  }
}

.page-grid{
  overflow: hidden;

  .grid-container{
    // border:1px solid red;
    
    height: calc(100vh - 135px) !important;

    // .grid-main{
    //   border:1px solid blue;
    // }

    &-role{
      height: calc(100vh - 160px) !important;
    }
  }

  
}