.home-hero-section {
  background-position: 0px 0px, 0px 0px, 0% 0%;
  background-size: auto, auto, cover;
  background-repeat: repeat, repeat, no-repeat;

  padding-top: 8rem;
  padding-bottom: 10rem;

  @include breakpoint(medium){
    padding-top: 16rem;
    padding-bottom: 20rem;
  }

  &.hero-bg-0{
    background-image: -webkit-gradient(linear, left bottom, left top, from(#032832), color-stop(76%, rgba(3, 40, 50, 0))),
    -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.1))),
    url(../../public/static/images/Air-Freight-Heder.jpg);
  background-image: linear-gradient(0deg, #032832, rgba(3, 40, 50, 0) 76%),
    linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
    url(../../public/static/images/Air-Freight-Heder.jpg);
  }
  &.hero-bg-1{
    background-image: -webkit-gradient(linear, left bottom, left top, from(#032832), color-stop(76%, rgba(3, 40, 50, 0))),
    -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.1))),
    url(../../public/static/images/Air-Freight-CTA.jpg);
  background-image: linear-gradient(0deg, #032832, rgba(3, 40, 50, 0) 76%),
    linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
    url(../../public/static/images/Air-Freight-CTA.jpg);
  }
  &.hero-bg-2{
    background-image: -webkit-gradient(linear, left bottom, left top, from(#032832), color-stop(76%, rgba(3, 40, 50, 0))),
    -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.1))),
    url(../../public/static/images/All-Footer-CTA-Image.jpeg);
  background-image: linear-gradient(0deg, #032832, rgba(3, 40, 50, 0) 76%),
    linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
    url(../../public/static/images/All-Footer-CTA-Image.jpeg);
  }
  &.hero-bg-testimonial{
    background-image: -webkit-gradient(linear, left bottom, left top, from(#032832), color-stop(76%, rgba(3, 40, 50, 0))),
    -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.1))),
    url(../../public/static/images/Computer-Image.jpeg);
  background-image: linear-gradient(0deg, #032832, rgba(3, 40, 50, 0) 76%),
    linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
    url(../../public/static/images/Computer-Image.jpeg);
    
  }

  .hero-text-content {
    max-width: 50%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  
    h1,
    p {
      color: #fff;
      margin-bottom: 1rem;
    }
    button{
      text-transform: capitalize;
      margin-top: 1rem;
      margin-right: 1rem;
    }
  }

}
