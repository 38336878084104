
/*********************
BREAKPOINTS
*********************/

@mixin breakpoint($point) {
    @if $point == 'large' {
      @media (min-width: 64em) {
        @content;
      }
    } @else if $point == 'large only' {
      @media (min-width: 50em) and (max-width: 74.9375em) {
        @content;
      }
    } @else if $point == 'medium' {
      @media (min-width: 40em) {
        @content;
      }
    } @else if $point == 'medium only' {
      @media (max-width: 40em) and (max-width: 63.9375em) {
        @content;
      }
    } @else if $point == 'small only' {
      @media (max-width: 39.9375em) {
        @content;
      }
    }
  }
  
  // @mixin breakpoint($point) {
  //   @if $point == desktop {
  //     @media (min-width: 70em) {
  //       @content;
  //     }
  //   } @else if $point == laptop {
  //     @media (min-width: 64em) {
  //       @content;
  //     }
  //   } @else if $point == tablet {
  //     @media (min-width: 50em) {
  //       @content;
  //     }
  //   } @else if $point == phablet {
  //     @media (min-width: 37.5em) {
  //       @content;
  //     }
  //   } @else if $point == mobileonly {
  //     @media (max-width: 37.5em) {
  //       @content;
  //     }
  //   }
  // }
  